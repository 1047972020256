const env = import.meta.env;

const isLocalDev = window.location.host.includes('localhost');
const urlParams = new URLSearchParams(window.location.search);

const host = window.location.host.split('.');
const isProd = host.length === 3 && host[0] === "cloud";
const backendBase = urlParams.get('d') ?? env.VITE_API_BACKEND_BASE;


const getDatacenter = () => {
    const hasDCSelectedInDomain =  ['gcp', 'aws'].includes(host[1]);
    const dcInParams = urlParams.has('dc');
    if (!hasDCSelectedInDomain && !dcInParams) {
        return null;
    }

    return hasDCSelectedInDomain ? host[1] : urlParams.get('dc');

}

const getEnv = () => {

    if (isProd) {
        return "production";
    }

    if (isLocalDev) {
        return env.VITE_BACKEND_ENV ? env.VITE_BACKEND_ENV : "development";
    }

    if (window.location.host.startsWith("preview")) {
        return "preview";
    }

    if (window.location.host.startsWith("staging")) {
        return "staging";
    }

    const devDomainSpecs = [
        { domain: '.composable.sh', regex: /.+git\-([a-z0-9\-]+)\.composable\.sh/ },
        { domain: '.vertesia.app', regex: /.+git\-([a-z0-9\-]+)\.vertesia\.app/ },
        { domain: '.vertesia.dev', regex: /.+git\-([a-z0-9\-]+)\.vertesia\.dev/ },
    ];

    for (const d of devDomainSpecs) {
        if (window.location.host.endsWith(d.domain)) {
            const match = window.location.host.match(d.regex);
            console.log('Match', match);
            if (match) {
                return 'dev-' + match[1];
            }
        }
    };
}

const envType = getEnv();
if (!envType) {
    throw new Error('Could not determine environment type');
} else {
    console.log('Environment is:', envType);
}


const getServerUrl = (service: string, env: string) => {
    //const params = useParams();

    if (isLocalDev) {
        if (backendBase) {
            return `https://${service}-server-${env}.${backendBase}`;
        } else {
            if (service === "studio") {
                return `http://127.0.0.1:8091`;
            }
            if (service === "zeno") {
                return `http://127.0.0.1:8092`;
            }
        }
    }

    if (!backendBase) {
        throw new Error('VITE_API_BACKEND_BASE not set');
    }

    const suffix = getDatacenter() ? `${getDatacenter()}.${backendBase}` : backendBase; 
    const serverUrl = `https://${service}-server-${env}.${suffix}`;
    console.log(`Server URL for ${service} is: ${serverUrl}`);
    return serverUrl;
};

const getBaseUrl = () => {

    if (env.BASE_URL) {
        return env.BASE_URL
    }

    return `https://${window.location.host}`;

}

function getAuthDomain() {
    if (isLocalDev && typeof window !== 'undefined') {
        // use current host since vite may change the port
        console.log('Using local dev auth domain:', window.location.host);
        return window.location.host;
    }

    return window.location.host;
}

const authDomain = getAuthDomain();

let isDatadogRumEnabled;

if (env.DATADOG_RUM_ENABLED) {
    // override with env var always take precedence
    isDatadogRumEnabled = env.DATADOG_RUM_ENABLED === "true";
} else if (isLocalDev) {
     // disable RUM by default in local dev
    isDatadogRumEnabled = false;
} else {
     // enable RUM by default in cloud envs
    isDatadogRumEnabled = true;
}

const Env = {
    version: __VERCEL_ENV_COMMIT_SHA__,
    isProd: isProd,
    isDev: !isProd,
    isPreview: envType === "preview",
    isLocalDev,
    type: envType,
    BASE_URL: getBaseUrl(),
    endpoints: {
        zeno: getServerUrl('zeno', envType),
        studio: getServerUrl('studio', envType),
    },
    API_PATH: `/api/v1`,
    firebase: {
        apiKey: env.VITE_FIREBASE_API_KEY,
        authDomain,
        projectId: "dengenlabs",
    },
    sentry: {
        dsn: env.VITE_SENTRY_DSN
    },
    datadog: isDatadogRumEnabled,
};

if (!Env.isProd) {
    console.log("Initializing App with Env", Env);
}

export default Env;