import BlinkingCursor from "@/modules/studio/components/BlinkingCursor";
import PlaygroundState, { PlaygroundExecutionStatus } from "@/modules/studio/features/interaction/playground/PlaygroundState";
import { getScrollParent, scrollToBottom } from "@/modules/studio/utils/scroll";
import { getComposableToken } from "@/session/auth/composable";
import { useUserSession } from "@/session/UserSession";
import { ExecutionRun } from "@vertesia/common";
import { Center, ErrorBox, Spinner } from "@reactik/components";
import { useCompositeState, useEventSource, useIntersectionObserver } from "@reactik/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface StreamingPanelProps {
    playgroundState: PlaygroundState;
}
export default function StreamingPanel({ playgroundState }: StreamingPanelProps) {
    const run = playgroundState.executionRun;
    switch (playgroundState.status) {
        case PlaygroundExecutionStatus.initial:
            return (
                <div className='flex w-full justify-center pt-8 text-sm semibold text-gray-700 dark:text-gray-300'>
                    Run the interaction to see the result here
                </div>
            )
        case PlaygroundExecutionStatus.pending:
            return (
                <Center className='py-4'>
                    <Spinner size="lg" />
                </Center>
            )
        case PlaygroundExecutionStatus.streaming:
            return (
                <_StreamingPanel run={run!} />
            )
        case PlaygroundExecutionStatus.error:
            return <ErrorBox title="Failed to run interaction">
                <div>{playgroundState.error?.message || "Unknown Error"}</div>
            </ErrorBox>;
        default:
            return (
                <_StreamingDonePanel />
            )
    }
}

function _StreamingDonePanel() {
    const playgroundState = useCompositeState(PlaygroundState);
    return (
        <pre className="whitespace-pre-wrap">
            {playgroundState.streamingText.value || ''}
        </pre>
    )
}

interface _StreamingPanelProps {
    run: ExecutionRun;
}

function _StreamingPanel({ run }: _StreamingPanelProps) {
    const [text, setText] = useState('');
    const session = useUserSession();
    const playgroundState = useCompositeState(PlaygroundState);


    const onMessage = (text: string) => {
        playgroundState.streamingText.value = text;
        setText(text);
    };

    const onCompletion = (run: ExecutionRun) => {
        playgroundState.executionRun = run;
        playgroundState.executionStatus.value = PlaygroundExecutionStatus.done;
    };

    const urlFn = useCallback(async () => {
        const url = new URL(session.client.runs.getUrl(`/${run.id}/stream`));
        const token = await session.rawAuthToken;
        if (!token) {
            throw new Error('No auth token available');
        }
        url.searchParams.set('access_token', token);
        return url.toString();
    }, [run.id, session.user?.sub]);


    useEventSource(urlFn, onMessage, onCompletion);

    const bottomRef = useRef<HTMLDivElement>(null);

    // scrool down as text overflow the panel
    useIntersectionObserver(bottomRef, () => {
        const el = bottomRef.current;
        if (el) {
            const scrollable = getScrollParent(el);
            scrollable && scrollToBottom(scrollable)
        }
    }, {
        leave: true // listen for when the element leave the viewport
    });

    let content;
    if (text) {
        const lines = text.split('\n');
        const len = lines.length;
        content = lines.map((line, index) => {
            const last = index === len - 1 ? <BlinkingCursor /> : null;
            if (line.startsWith('Error:')) {
                // virtual driver error message
                return <div key={index} className="text-red-500">{line}{last}</div>
            } else if (line.startsWith('Info:')) {
                // virtual driver info message
                return <div key={index} className={"text-blue-500"}>{line}{last}</div>
            } else {
                return <div key={index}>{line}{last}</div>
            }
        })
    } else {
        content = <BlinkingCursor />
    }
    return <div>
        <pre className="whitespace-pre-wrap">{content}</pre>
        <div ref={bottomRef} />
    </div>;
}
