import ObjectsTable from "@/modules/store/features/store/objects/ObjectsTable";
import { useSearch, useWatchSearchFacets, useWatchSearchResult } from "@/modules/store/features/store/objects/search/SearchContext";
import SearchProvider from "@/modules/store/features/store/objects/search/SearchProvider";
import { FacetsNav } from "@/features/facets/FacetsNav";
import { ColumnLayout, ContentObjectItem } from "@vertesia/common";
import { ErrorBox, Input, Spinner } from "@reactik/components";
import { useDebounce, useIntersectionObserver } from "@reactik/hooks";
import { useEffect, useRef, useState } from "react";

const layout: ColumnLayout[] = [
    { "name": "Name", "field": "properties.title", "type": "string", "fallback": "name" },
    { "name": "Type", "field": "type.name", "type": "string" },
    { "name": "Status", "field": "status", "type": "string" },
    { "name": "Created At", "field": "created_at", "type": "date" }
];

interface SelectDocumentProps {
    onChange: (value: ContentObjectItem) => void;
    type?: string;
    mimeType?: string;
}
export default function SelectDocument({ onChange }: SelectDocumentProps) {
    const onRowClick = (selected: ContentObjectItem) => {
        onChange(selected || undefined);
    }
    return (
        <SearchProvider>
            <_SelectDocument onRowClick={onRowClick} />
        </SearchProvider>
    )
}

interface _SelectDocumentProps {
    onRowClick: (selected: ContentObjectItem) => void;
}
function _SelectDocument({ onRowClick }: _SelectDocumentProps) {
    const [isReady, setIsReady] = useState(false);
    const { search, isLoading, error, objects } = useWatchSearchResult();

    const loadMoreRef = useRef<HTMLDivElement>(null);
    useIntersectionObserver(loadMoreRef, () => {
        isReady && search.loadMore(true)
    }, { deps: [isReady] });
    useEffect(() => {
        search.search().then(() => setIsReady(true));
    }, []);

    if (error) {
        return <ErrorBox title="Search failed">{error.message}</ErrorBox>
    }

    const [searchTerm, setSearchTerm] = useState('');
    const debounceValue = useDebounce(searchTerm, 500);
    useEffect(() => {
        search.query.name = searchTerm;
        search.search().then(() => setIsReady(true));
    }, [debounceValue]);

    const facets = useWatchSearchFacets();
    const facetSearch = useSearch();

    return (
        <div>
            <Input className="w-full mb-4" placeholder="Filter content" value={searchTerm} onChange={setSearchTerm} />
            <FacetsNav facets={facets} search={facetSearch} />
            <ObjectsTable objects={objects || []} isLoading={false} layout={layout} onRowClick={onRowClick} />
            <div ref={loadMoreRef} className='mt-10' />
            {
                isLoading && <div className='flex justify-center'><Spinner size='xl' /></div>
            }
        </div>
    )
}
