import { VertesiaClient } from "@vertesia/client";
import { ContentObjectType, ContentObjectTypeRef } from "@vertesia/common";
import { SchemaSource } from "@reactik/schema-editor";
import { JSONSchema4 } from "json-schema";

const STORE_PREFIX = "store:";

export function resolveSchema(client: VertesiaClient, uri: string) {
    if (uri.startsWith(STORE_PREFIX)) {
        return client.types.retrieve(uri.substring(STORE_PREFIX.length)).then((type: ContentObjectType) => {
            return {
                name: type.name,
                uri: uri,
                schema: type.object_schema as JSONSchema4
            };
        });
    } else {
        throw new Error(`Unsupported schema URI: ${uri}`);
    }
}

export function schemaSourceToTypeRef(source: SchemaSource | undefined | null): ContentObjectTypeRef | undefined {
    return source ? {
        id: source.uri.substring(STORE_PREFIX.length),
        name: source.name
    } : undefined;
}

export function typeRefToSchemaSource(typeRef: ContentObjectTypeRef | undefined | null): SchemaSource | undefined {
    return typeRef ? {
        name: typeRef.name,
        uri: getSchemaUri(typeRef),
    } : undefined;
}

export function getSchemaUri(typeRef: ContentObjectTypeRef): string {
    return STORE_PREFIX + typeRef.id;
}


export function updateContentTypeSchema(client: VertesiaClient, uri: string, schema: JSONSchema4) {
    return client.types.update(uri.substring(STORE_PREFIX.length), { object_schema: schema })
}
