import { useEffect, useMemo, useState } from 'react';

import PanelErrorBoundary from '@/errors/PanelErrorBoundary';
import InteractionViewState from '@/modules/studio/features/interaction/InteractionViewState';
import PlaygroundState, { ConfigValidationError } from '@/modules/studio/features/interaction/playground/PlaygroundState';
import RunConfigurationEditor from '@/modules/studio/features/interaction/playground/RunConfigurationEditor';
import RunPayloadEditor from '@/modules/studio/features/interaction/playground/RunPayloadEditor';
import { SchemaOverrideEditor } from '@/modules/studio/features/interaction/playground/SchemaOverrideEditor';
import EditorLayout from '@/modules/studio/features/prompt/editor/EditorLayout';
import { useUserSession } from '@/session/UserSession';
import useUXTracking from '@/session/useUXTracking';
import { ExecutionEnvironmentRef, ExecutionRun, RunDataStorageLevel } from '@vertesia/common';
import { PlayIcon } from '@heroicons/react/20/solid';
import { Button, Tab, Tabs, TabsBar, TabsPanel, useToast } from '@reactik/components';
import { useCompositeState, useSetCompositeStateProperty } from '@reactik/hooks';

import type { JSONObject } from "@llumiverse/core";

const getTabs = (onTestDataChange: () => void): Tab[] => {
    return [
        {
            name: "parameters",
            label: "Parameters",
            content: <RunPayloadEditor onChange={onTestDataChange} />
        },
        {
            name: "configuration",
            label: "Configuration",
            content: <RunConfigurationEditor />
        },
        {
            name: "result_schema",
            label: "Result Schema",
            content: <SchemaOverrideEditor />
        }
    ];
}

export default function ExecutionRequestView() {
    const { client } = useUserSession();
    const playgroundState = useCompositeState(PlaygroundState);
    const interaction = playgroundState.interaction;
    const [env, setEnv] = useState<ExecutionEnvironmentRef | undefined>(playgroundState.env || undefined);

    useEffect(() => {
        playgroundState.config = {
            max_tokens: playgroundState.config?.max_tokens || interaction.max_tokens,
            temperature: playgroundState.config?.temperature || interaction.temperature,
            top_k: playgroundState.config?.top_k || interaction.top_k,
            top_p: playgroundState.config?.top_p || interaction.top_p,
            top_logprobs: playgroundState.config?.top_logprobs || interaction.top_logprobs,
            presence_penalty: playgroundState.config?.presence_penalty || interaction.presence_penalty,
            frequency_penalty: playgroundState.config?.frequency_penalty || interaction.frequency_penalty,
            stop_sequence: playgroundState.config?.stop_sequence || interaction.stop_sequence,
            run_data: playgroundState.config?.run_data || interaction.restriction,
            model: playgroundState.config?.model || interaction.model,
            environment: env?.id
        };
        playgroundState.env = env;
        playgroundState.result_schema = playgroundState.result_schema || interaction.result_schema;
    }, [env, playgroundState]);

    useEffect(() => {
        if (!playgroundState.env && interaction.environment) {
            client.environments
                .retrieve(interaction.environment as string)
                .then((env) => {
                    setEnv(env);
                })
                .catch((err) => {
                    console.error("Failed to load execution environment", err);
                });
        }
    }, [interaction.environment, client.environments]);

    const sharedState = useCompositeState(InteractionViewState);

    const onTestDataChange = () => {
        if (sharedState) {
            sharedState.isDirty.value = true;
        }
    };

    return (
        <EditorLayout title="Execution Payload" actions={<RunButton />}>
            <Tabs tabs={getTabs(onTestDataChange)}>
                <TabsBar />
                <PanelErrorBoundary>
                    <TabsPanel />
                </PanelErrorBoundary>
            </Tabs>
            {sharedState && <SaveProvider sharedState={sharedState} />}
        </EditorLayout>
    );
}

interface SaveProviderProps {
    sharedState: InteractionViewState;
}
function SaveProvider({ sharedState }: SaveProviderProps) {
    const playgroundState = useCompositeState(PlaygroundState);
    const { client } = useUserSession();

    const onSaveTestData = useMemo(() => () => {
        return client.interactions.update(playgroundState.interaction.id, {
            test_data: playgroundState.payload.value as JSONObject,
        });
    }, [playgroundState.interaction]);

    useSetCompositeStateProperty(sharedState.save, onSaveTestData);

    return null;
}

function RunButton() {
    const toast = useToast();
    const [isRunning, setIsRunning] = useState(false);
    const playgroundState = useCompositeState(PlaygroundState);
    const { trackEvent } = useUXTracking();

    const run = () => {
        setIsRunning(true);
        playgroundState
            .run()
            .then((run: ExecutionRun) => {
                trackEvent("run_interaction", {
                    zone: 'playground',
                    model: run.modelId,
                    status: run.status
                });
            }).catch((err: any) => {
                let title, description;
                if (err instanceof ConfigValidationError) {
                    title = "Invalid configuration";
                    description = err.message;
                } else {
                    title = "Failed to run interaction";
                    description = err.message;
                }
                toast({
                    status: "error",
                    title,
                    description,
                    duration: 6000,
                });
            })
            .finally(() => {
                setIsRunning(false);
            });
    };

    return (
        <Button isLoading={isRunning} onClick={run}>
            <PlayIcon className="w-5 h-5" />
            Run
        </Button>
    );
}