import { useUserSession } from "@/session/UserSession";
import useUXTracking from "@/session/useUXTracking";
import { ExecutionEnvironment, SupportedProviderParams, SupportedProviders, SupportedProvidersList } from "@vertesia/common";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalTitle, SelectBox, useToast } from "@reactik/components";
import { useNavigate } from "@reactik/router";
import { useState } from "react";

interface CreateEnvironmentModalProps {
    isOpen?: boolean
    onClose: (env?: ExecutionEnvironment) => void;
}
export default function CreateEnvironmentModal({ isOpen = false, onClose }: CreateEnvironmentModalProps) {
    return (
        <Modal className="relative overflow-visible" onClose={onClose} isOpen={isOpen}>
            <ModalTitle>Declare a New Execution Environment</ModalTitle>
            <CreateEnvironmentForm onClose={onClose} />
        </Modal>
    )
}

interface CreateEnvironmentFormProps {
    onClose: (env?: ExecutionEnvironment) => void;
}
function CreateEnvironmentForm({ onClose }: CreateEnvironmentFormProps) {
    const toast = useToast();
    const { client, project } = useUserSession();
    const [envName, setEnvName] = useState('');
    const [envUrl, setEnvUrl] = useState('');
    const [envApiKey, setEnvApiKey] = useState('');
    const [provider, setProvider] = useState<SupportedProviderParams | undefined>();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const providers = Object.values(SupportedProvidersList).filter(p => p.id !== SupportedProviders.test);
    const { trackEvent } = useUXTracking();

    const onSubmit = () => {
        if (!provider) {
            toast({
                status: 'error',
                title: 'Invalid provider',
                description: 'Please select a provider',
                duration: 5000
            })
            return;
        }

        const name = envName.trim();
        if (!name) {
            toast({
                status: 'error',
                title: 'Invalid environment name',
                description: 'Please specify a name for the environment',
                duration: 5000
            })
            return;
        }

        const url = envUrl.trim();
        if (!url && SupportedProvidersList[provider.id].requiresEndpointUrl) {
            toast({
                status: 'error',
                title: 'Invalid environment URL',
                description: 'This provider requires a URL to be specified',
                duration: 5000
            })
            return
        }

        if (!envApiKey && SupportedProvidersList[provider.id].requiresApiKey) {
            toast({
                status: 'error',
                title: 'Invalid API Key',
                description: 'Please specify an API Key',
                duration: 5000
            })
            return
        }

        setLoading(true);
        const promise = client.environments.create(
            {
                name: name,
                endpoint_url: url,
                allowed_projects: project ? [project.id] : undefined,
                apiKey: envApiKey.trim(),
                provider: SupportedProviders[provider.id]
            }
        );
        promise.then((env: ExecutionEnvironment) => {
            onClose(env);
            trackEvent('environment_created', { provider: env.provider });
            navigate('/environments/' + env.id);
            toast({
                status: 'success',
                title: 'Environment created: Enable models now',
                description: 'Your new environment has been created. Please search and enable models to use them in interactions',
            })
        }).catch((err) => {
            toast({
                status: 'error',
                title: 'Failed to create environment',
                description: err.message,
                duration: 5000
            })
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <ModalBody>
                <div className="mb-4">
                    <div className='mb-2 w-full'>Environment Name</div>
                    <Input className="w-full" value={envName} onChange={setEnvName} />
                </div>
                <div className="mb-4">
                    <div className='mb-2'>Provider</div>
                    <SelectBox className="w-full"
                        value={provider}
                        options={providers}
                        onChange={setProvider}
                        optionLabel={(option) => option.name}
                        filterBy="name"
                        placeholder='Select a provider'
                    />
                </div>
                <div className="mb-4">
                    <div className='mb-2'>Endpoint URL</div>
                    <Input className="disabled:bg-slate-200 w-full"
                        value={envUrl}
                        onChange={setEnvUrl}
                        placeholder={provider?.endpointPlaceholder ? provider.endpointPlaceholder : !provider?.requiresEndpointUrl ? "provider's default" : undefined}
                    />
                </div>
                <div>
                    <div className='mb-2 w-full'>API Key</div>
                    <Input className="disabled:bg-slate-200 w-full"
                        value={envApiKey}
                        onChange={setEnvApiKey}
                        placeholder={!provider?.requiresApiKey ? "not required" : undefined}
                    />
                </div>
            </ModalBody>
            <ModalFooter justify="end">
                <Button isLoading={isLoading} onClick={onSubmit}>Create</Button>
            </ModalFooter>
        </>
    )
}
