import { useUserSession } from "@/session/UserSession";
import { AccessControlEntry, AccessControlledResource, ProjectRef, ResolvableRef, ResolvableRefType, ResourceRef } from "@vertesia/common";
import { useFetch } from "@reactik/hooks";
import { createContext, useContext, useMemo } from "react";

export class AccessControlEntryRegistry {
    isLoading = false;
    constructor(public aces: AccessControlEntry[], public refs: ResourceRef[], public refresh: () => Promise<unknown>) {
    }
}
export const LoadingAccessControlEntryRegistry = new AccessControlEntryRegistry([], [], () => Promise.resolve());
LoadingAccessControlEntryRegistry.isLoading = true;

export const PermissionsProviderReactContext = createContext<AccessControlEntryRegistry | null>(null);

export function useAccessControlEntryRegistry() {
    const registry = useContext(PermissionsProviderReactContext);
    if (!registry) {
        throw new Error('useAccessControlEntryRegistry must be used within a AccessControlEntryProvider')
    }
    return registry;
}
