import { useUserSession } from "@/session/UserSession";
import { ExecutionEnvironment, ExecutionEnvironmentRef } from "@vertesia/common";
import { ErrorBox, SelectBox, Spinner } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import { useEffect, useState } from "react";
import CreateEnvironmentModal from "./CreateEnvironmentModal";

interface SelectOrCreateEnvironmentProps {
    value?: ExecutionEnvironment | ExecutionEnvironmentRef | string;
    onChange: (env: ExecutionEnvironmentRef) => void
    selectFirst?: boolean; // if true and value is not defined the the first enviroment will be selected
}
export default function SelectOrCreateEnvironment({ value, onChange, selectFirst }: SelectOrCreateEnvironmentProps) {
    const [actualValue, setActualValue] = useState<ExecutionEnvironment | ExecutionEnvironmentRef | string | undefined>(value);
    const [showModal, setShowModal] = useState(false);
    const { account, client } = useUserSession();

    const { data: envs, error, setData } = useFetch(() => {
        return client.environments.list().then((envs) => {
            if (!value && selectFirst) {
                // init with the first environment
                setActualValue(envs[0]);
                onChange(envs[0]);
            }
            return envs;
        });
    }, [account]);

    useEffect(() => {
        // if the vakue is not known trigger an onchange
        setActualValue(value);
    }, [value]);

    const onModalClose = (env?: ExecutionEnvironmentRef) => {
        setShowModal(false);
        if (env) {
            setData([env].concat(envs!))
            setActualValue(env);
            onChange(env)
        }
    }

    if (error) {
        return <ErrorBox title="Failed to fetch enviroments">{error.message}</ErrorBox>
    }

    const getEnvValue = (value?: ExecutionEnvironment | ExecutionEnvironmentRef | string) => {

        if (!value) return undefined;

        if (typeof value === 'string') {
            return envs?.find((env) => env.id === value);
        } else {
            return envs?.find((env) => env.id === value.id);
        }

    }

    return envs ? (
        <div className="flex items-center space-x-2">
            <SelectBox by='id'
                className='flex-1 w-full'
                value={getEnvValue(actualValue) ?? undefined}
                options={envs}
                optionLabel={(option) => option.name}
                addNew={() => setShowModal(true)}
                addNewLabel="Create New Environment"
                placeholder="Select Environment"
                filterBy="name"
                onChange={(env) => onChange(env)} />
            <CreateEnvironmentModal isOpen={showModal} onClose={onModalClose} />
        </div>
    ) :
        (
            <div className="flex justify-center">
                <Spinner size="sm" />
            </div>
        );
}