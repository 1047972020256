import { useUserSession } from "@/session/UserSession";
import { AwsConfiguration, GithubConfiguration, SupportedIntegrations } from "@vertesia/common";
import { Button, Input, Spinner, Switch, useToast } from "@reactik/components";
import { useFetch } from "@reactik/hooks";


const defaultConfig: AwsConfiguration = {
    enabled: false,
    s3_role_arn: '',
}

export default function AwsSettings() {
    const { client, project } = useUserSession();
    const toast = useToast();

    const { data: config, refetch, isLoading, setData } = useFetch(() => client.projects.integrations.retrieve(project!.id, SupportedIntegrations.aws) as Promise<AwsConfiguration>, [project])

    const onSave = () => {
        client.projects.integrations.update(project!.id, SupportedIntegrations.aws, config).then(() => {
            toast({
                title: 'Settings saved',
                status: 'success'
            });
            refetch();
        }).catch((e) => {
            toast({
                title: 'Failed to save settings',
                status: 'error'
            });
        });
    }

    return (
        <div key="gladia" className="">
            <h3 className="text-lg font-semibold py-2">AWS</h3>
            <p className="pb-4 text-sm">This integration allows you to use to connect your AWS account to a Composable Project.</p>

            {isLoading ?
                <Spinner />
                :
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4 align-middle">
                        <label className="text-nowrap">Enabled</label>
                        <Switch value={config?.enabled ?? false} onChange={(value) => setData({ ...config ?? defaultConfig, enabled: value })} />
                    </div>
                    <div className="flex flex-col gap-2 align-middle">
                        <label className="text-nowrap">Role ARN to access S3</label>
                        <Input className="w-full"
                            value={config?.s3_role_arn}
                            onChange={(value) => setData({ ...config ?? defaultConfig, s3_role_arn: value })}
                        />
                    </div>
                    <Button onClick={onSave}>Save</Button>
                </div>
            }
        </div>

    )
}