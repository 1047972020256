import { useUserSession } from "@/session/UserSession";
import { ProjectRef } from "@vertesia/common";
import { Portal, SelectBox } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import clsx from "clsx";
import { useState } from "react";
import CreateProjectModal from "./CreateProjectModal";


interface SelectProjectNavProps {
    className?: string
}
export default function SelectProjectNav({ className }: SelectProjectNavProps) {
    const { client, project, accounts, user, account } = useUserSession();
    const onProjectChange = (project: ProjectRef) => {
        if (project.id === "new") return; //if we selected create, ignore
        if (!account) return;
        window.location.replace(`/?a=${account?.id}&p=${project.id}`);
    };

    const { data: projects, isLoading } = useFetch(() => {
        if ((!user || !accounts) || isLoading) return Promise.resolve([]);
        return client.projects.list(account?.id ? [account?.id] : undefined);
    }, [accounts, user]);


    return (
        <ProjectSelector
            className={clsx('w-auto', className)}
            selected={project ?? undefined}
            onChange={onProjectChange}
            projects={projects ?? []}
            placeholder={isLoading ? 'Loading Projects...' : 'Select Project'}
            allowCreate
        />
    );
}

interface ProjectSelectorProps {
    className?: string
    onChange: (project: ProjectRef) => void
    selected?: ProjectRef
    projects: ProjectRef[]
    allowCreate?: boolean
    placeholder?: string
}

export function ProjectSelector({ className, onChange, projects, selected, placeholder, allowCreate }: ProjectSelectorProps) {

    const { accounts } = useUserSession();
    const [showModal, setShowModal] = useState(false);

    //check if we have multiple orgs in the list of projects
    const hasMultipleOrgs = projects.some((p, i) => i > 0 && p.account !== projects[i - 1].account);

    const getProjectLabel = (project: ProjectRef) => {
        if (!hasMultipleOrgs) return project.name;

        const account = accounts?.find(a => a.id === project.account);
        return `[${account?.name}] ${project.name}`;
    };

    return (
        <>
            <SelectBox
                by="name"
                className={clsx('w-auto', className)}
                value={selected}
                options={projects}
                optionLabel={(option) => getProjectLabel(option)}
                addNew={allowCreate ? () => setShowModal(true) : undefined}
                addNewLabel={allowCreate ? 'Create New Project' : undefined}
                placeholder={placeholder ?? 'Select Project'}
                filterBy="name"
                onChange={onChange} />
            <Portal>
                <CreateProjectModal isOpen={showModal} onClose={() => setShowModal(false)} />
            </Portal>
        </>
    );
}