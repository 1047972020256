import { ChatPromptSchema, PromptSegmentDef, PromptSegmentDefType, PromptTemplate } from "@vertesia/common";
import type { JSONObject } from "@llumiverse/core";
import { PromptRole } from "@llumiverse/core";
import { JSONSchema4, JSONSchema4TypeName } from "json-schema";

function isOfType(schema: JSONSchema4, type: JSONSchema4TypeName) {
    if (Array.isArray(schema.type)) {
        return schema.type.includes(type);
    } else {
        return schema.type === type;
    }
}

function propFromSchema(obj: JSONObject, name: string, schema: JSONSchema4) {
    if (isOfType(schema, 'object')) {
        obj[name] = generateFromSchema(schema);
    } else if (isOfType(schema, 'array')) {
        if (schema.items && isOfType(schema.items as JSONSchema4, "object")) {
            obj[name] = [generateFromSchema(schema.items)];
        } else {
            obj[name] = [];
        }
    } else if (isOfType(schema, 'string')) {
        obj[name] = '';
    } else if (isOfType(schema, 'boolean')) {
        obj[name] = false;
    } else if (isOfType(schema, 'number') || isOfType(schema, 'integer')) {
        obj[name] = 0;
    } else {
        obj[name] = null;
    }
    return obj;
}

export function generateFromSchema(schema: JSONSchema4): JSONObject {
    if (schema.properties) {
        const obj: JSONObject = {};
        for (const name of Object.keys(schema.properties)) {
            propFromSchema(obj, name, schema.properties[name]);
        }
        return obj;
    } else {
        return {};
    }
}

export function generateFromSegments(segments: PromptSegmentDef<PromptTemplate>[]): JSONObject | undefined {
    if (!segments) return undefined;
    let out = {};
    for (const segment of segments) {
        if (segment.template && segment.template.inputSchema) {
            const obj = generateFromSchema(segment.template.inputSchema);
            Object.assign(out, obj);
        } else if (segment.type === PromptSegmentDefType.chat) {
            Object.assign(out, {
                chat: [
                    {
                        role: PromptRole.user,
                        content: "Hi"
                    },
                    {
                        role: PromptRole.assistant,
                        content: "Hello"
                    }
                ] as ChatPromptSchema[]
            });
        }
    }
    return out;
}

export function contentToJSON(content: string | undefined | null) {
    if (!content) return undefined;
    content = content.trim();
    if (!content) return undefined;
    return JSON.parse(content);
}

export function jsonToContent(json: JSONObject | undefined | null) {
    if (!json) return '';
    return JSON.stringify(json, null, 2);
}
