import { ObjectKey, ObjectVisitor, ObjectWalker } from "@vertesia/json";
import { useEffect, useState } from "react";
import "./json-code.css";

abstract class Block {
    result: string[] = [];

    constructor(public parent: Block | undefined, public key: ObjectKey, public indent: string) { }

    writeKey(key: ObjectKey) {
        const type = typeof key;
        if (type === 'string') {
            if (this.result.length > 0) {
                this.result.push("<span class='json-comma'>,\n</span>");
            }
            this.indent && this.result.push(this.indent);
            this.result.push(`<span class="json-key">"${key}"</span><span class='json-assign'>: </span>`);
        } else if (type === 'number') {
            if (this.result.length > 0) {
                this.result.push("<span class='json-comma'>,\n</span>");
            }
            this.indent && this.result.push(this.indent);
        }
    }
    pushValue(key: ObjectKey, value: any) {
        this.writeKey(key);
        if (value === null) {
            this.result.push("<span class='json-null'>null</span>");
        } else if (value instanceof Block) {
            this.result.push(value.renderStart() + value.renderValue() + value.renderEnd());
        } else {
            let valueClass;
            const type = typeof value;
            if (type === 'string') {
                value = `"${value}"`;
                value = value.replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;");
                valueClass = 'json-string';
            } else if (type === 'number') {
                valueClass = 'json-number';
            } else if (type === 'boolean') {
                valueClass = 'json-boolean';
            }
            this.result.push(`<span class='${valueClass}'>${value}</span>`);
        }
    }
    renderValue() {
        return this.result.join('');
    }
    abstract renderStart(): string;
    abstract renderEnd(): string;
}

class ObjectBlock extends Block {
    constructor(parent: Block | undefined, key: ObjectKey, indent: string) {
        super(parent, key, indent);
    }
    renderStart() {
        return "<span class='json-start-object'>{\n</span>";
    }
    renderEnd() {
        const NL = this.result.length > 0 ? '\n' : '';
        return `<span class='json-end-object'>${NL}${this.parent?.indent || ''}}</span>`;
    }
}

class ArrayBlock extends Block {

    constructor(parent: Block | undefined, key: ObjectKey, indent: string) {
        super(parent, key, indent);
    }
    renderStart() {
        return "<span class='json-start-array'>[\n</span>";
    }
    renderEnd() {
        const NL = this.result.length > 0 ? '\n' : '';
        return `<span class='json-end-array'>${NL}${this.parent?.indent || ''}]</span>`;
    }
}

class JsonRenderer implements ObjectVisitor {
    stack: Block[] = [];
    block: Block = new ObjectBlock(undefined, '', '');

    constructor(public tab = '  ') {
    }


    onStartObject(key: ObjectKey) {
        const nested = new ObjectBlock(this.block, key, this.block.indent + this.tab);
        this.stack.push(this.block);
        this.block = nested;
    }

    onEndObject() {
        const currentBlock = this.block;
        this.block = this.stack.pop()!;
        this.block.pushValue(currentBlock.key, currentBlock);
    }

    onStartIteration(key: ObjectKey) {
        const nested = new ArrayBlock(this.block, key, this.block.indent + this.tab);
        this.stack.push(this.block);
        this.block = nested;
    }

    onEndIteration() {
        const currentBlock = this.block;
        this.block = this.stack.pop()!;
        this.block.pushValue(currentBlock.key, currentBlock);
    }

    onValue(key: ObjectKey, value: any) {
        this.block.pushValue(key, value);
    }
}

function renderJson(json: any) {
    const renderer = new JsonRenderer();
    new ObjectWalker().walk(json, renderer);
    const out = renderer.block.renderValue();
    return <div dangerouslySetInnerHTML={{ __html: `<div class='json-code' style='overflow-x: scroll'>${out}</div>` }} />
}

export function JSONCode({ data }: { data: any; }) {
    const [element, setElement] = useState<React.ReactElement>();
    useEffect(() => {
        setElement(renderJson(data));
    }, [data]);
    return element;
}
