import { onAuthStateChanged } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { firebaseAuth } from "./auth/firebase";

import { UserNotFoundError, getComposableToken } from "./auth/composable";
//import useUXTracking from "./useUXTracking";
import { useAuthState } from "@/session/auth/useAuthState";
import { LastSelectedAccountId_KEY, LastSelectedProjectId_KEY, UserSession, UserSessionContext } from "./UserSession";


const devDomains = ['.composable.sh', '.vertesia.dev', 'vertesia.app'];
const CENTRAL_AUTH_REDIRECT = 'https://internal-auth.vertesia.app/';


interface UserSessionProviderProps {
    children: ReactNode | ReactNode[];
}
export function UserSessionProvider({ children }: UserSessionProviderProps) {
    //const { tagUserSession, trackEvent } = useUXTracking();
    const hashParams = new URLSearchParams(location.hash.substring(1));
    const token = hashParams.get('token');
    const state = hashParams.get('state');
    const [session, setSession] = useState<UserSession>(new UserSession());
    const { generateState, verifyState } = useAuthState();

    const redirectToCentralAuth = (projectId?: string, accountId?:string) => {   
        const url = new URL(CENTRAL_AUTH_REDIRECT);
        const currentUrl = new URL(window.location.href);
        currentUrl.hash = '';
        if (projectId) currentUrl.searchParams.set('p', projectId);
        if (accountId) currentUrl.searchParams.set('a', accountId);
        url.searchParams.set('redirect_uri', currentUrl.toString());
        url.searchParams.set('state', generateState());
        location.replace(url.toString());
    }

    useEffect(() => {
        console.log('Auth: starting auth flow');
        const currentUrl = new URL(window.location.href);
        const selectedAccount = currentUrl.searchParams.get('a') ?? localStorage.getItem(LastSelectedAccountId_KEY) ?? undefined
        const selectedProject = currentUrl.searchParams.get('p') ?? localStorage.getItem(LastSelectedProjectId_KEY + '-' + selectedAccount) ?? undefined
        console.log('Auth: selected account', selectedAccount);
        console.log('Auth: selected project', selectedProject);

        if (token && state) {
            if (!verifyState(state)) {
                console.error('Auth: invalid state');
                redirectToCentralAuth();
            }
            getComposableToken(selectedAccount, selectedProject, token).then(res => {
                session.login(res.rawToken).then(() => {
                    setSession(session.clone());
                    //cleanup the hash
                    window.location.hash = '';
                });
            }).catch(err => {
                console.error('Failed to fetch user token from studio, redirecting to central auth', err);
                redirectToCentralAuth();
            });
            return;
        } else {
            //if on a dev domain and not logged in, redirect to central auth
            if (!session.isLoggedIn()) {
                console.log('Auth: not logged in & no token/state');
                if (devDomains.some(domain => window.location.hostname.endsWith(domain))) {
                    console.log('Auth: on dev domain, redirecting to central auth with selection', selectedAccount, selectedProject);
                    redirectToCentralAuth();
                } else {
                    console.log('Auth: not on dev domain');
                }
            }
        }

        return onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
            if (firebaseUser) {
                console.log('Auth: successful login with firebase');
                session.setSession = setSession;
                await getComposableToken(selectedAccount, selectedProject).then(res => {
                    session.login(res.rawToken).then(() => setSession(session.clone()));
                }).catch(err => {
                    console.error('Failed to fetch user token from studio', err);
                    if (!(err instanceof UserNotFoundError)) session.logout();
                    session.isLoading = false;
                    session.authError = err;
                    setSession(session.clone());
                });

            } else {
                // anonymous user
                console.log('Auth: using anonymous user');
                session.client.withAuthCallback(undefined);
                session.logout();
                setSession(session.clone());
            }
        });
    }, []);


    return (
        <UserSessionContext.Provider value={session}>{children}</UserSessionContext.Provider>
    );

}
