import { useUserSession } from "@/session/UserSession";
import { GladiaConfiguration, SupportedIntegrations } from "@vertesia/common";
import { Button, Input, Spinner, Switch, useToast } from "@reactik/components";
import { useFetch } from "@reactik/hooks";


export default function GladiaSettings() {
    const { client, project } = useUserSession();
    const toast = useToast();

    const defaultConfig: GladiaConfiguration = {
        enabled: false,
        api_key: ''
    }

    const { data: config, isLoading, refetch, setData } = useFetch(() => client.projects.integrations.retrieve(project!.id, SupportedIntegrations.gladia) as Promise<GladiaConfiguration>, [project])

    const onSave = () => {
        client.projects.integrations.update(project!.id, SupportedIntegrations.gladia, config).then(() => {
            toast({
                title: 'Settings saved',
                status: 'success'
            });
            refetch();
        }).catch((e) => {
            toast({
                title: 'Failed to save settings',
                status: 'error'
            });
        });
    }



    return (
        <div key="gladia">
            <h3 className="text-lg font-semibold py-2">Gladia</h3>
            <p className="pb-4 text-sm">Gladia enabled audio and video transription</p>
            {isLoading ?
                <Spinner />
                :
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4 align-middle">
                        <label className="text-nowrap">Enabled</label>
                        <Switch value={config?.enabled ?? false} onChange={(value) => setData({ ...config ?? defaultConfig, enabled: value })} />
                    </div>
                    <div className="flex flex-col gap-2 align-middle">
                        <label className="text-nowrap align-middle">API Key</label>
                        <Input className="w-full" value={config?.api_key} onChange={(value) => setData({ ...config ?? defaultConfig, api_key: value })} />
                    </div>
                    <Button onClick={onSave}>Save</Button>
                </div>
            }
        </div>

    )
}