import PanelErrorBoundary from '@/errors/PanelErrorBoundary';
import { RunStatus } from '@/features/runs/components/RunStatus';
import PlaygroundState, { PlaygroundExecutionStatus } from '@/modules/studio/features/interaction/playground/PlaygroundState';
import PromptPanel from '@/modules/studio/features/interaction/playground/result/PromptPanel';
import ResultPanel from '@/modules/studio/features/interaction/playground/result/ResultPanel';
import StreamingPanel from '@/modules/studio/features/interaction/playground/result/StreamingPanel';
import EditorLayout from '@/modules/studio/features/prompt/editor/EditorLayout';
import { Tab, Tabs, TabsBar, TabsPanel } from '@reactik/components';
import { useCompositeState, useGetCompositeStateProperty } from '@reactik/hooks';
import { RunDataStorageLevel } from '@vertesia/common';

const getCurrentTab = (status: PlaygroundExecutionStatus) => {
    return status === PlaygroundExecutionStatus.done ? "result" : "streaming";
}

const getTabs = (playgroundState: PlaygroundState): Tab[] => {
    const status = playgroundState.status;
    const run = playgroundState.executionRun;
    let tabs: Tab[] = [];

    if (status === PlaygroundExecutionStatus.done) {
        tabs.push({
            name: "result",
            label: "Result",
            content: <ResultPanel run={run} />
        });

        if (run?.config.run_data === RunDataStorageLevel.DEBUG) {
            tabs.push(
                {
                    name: "prompt",
                    label: "Prompt",
                    content: <PromptPanel run={run} />
                }
            );
        }
    } else {
        tabs.push(
            {
                name: "streaming",
                label: "Streaming",
                content: <StreamingPanel playgroundState={playgroundState} />
            }
        );
    }

    return tabs;
}

export default function ExecutionResultView() {
    const playgroundState = useCompositeState(PlaygroundState);
    const status = useGetCompositeStateProperty(playgroundState.executionStatus);
    const isDone = status === PlaygroundExecutionStatus.done;

    return (
        <EditorLayout title="Execution Result">
            {
                playgroundState.executionRun && isDone && (
                    <>
                        <div className="flex items-center space-x-2 font-semibold text-sm text-gray-500 dark:text-gray-300">
                            <RunStatus status={playgroundState.executionRun.status} />
                            {
                                playgroundState.executionRun.execution_time
                                    ?
                                    <>
                                        <div>&bull;</div>
                                        <div>{"took " + (playgroundState.executionRun.execution_time / 1000).toFixed(2) + "s"}</div>
                                    </>
                                    : null
                            }
                            {
                                playgroundState.executionRun.token_use?.total ?
                                    <>
                                        <div>&bull;</div>
                                        <div>{"tokens: " + playgroundState.executionRun.token_use.result + " / " + playgroundState.executionRun.token_use.total}</div>
                                    </>
                                    : null
                            }
                            {
                                playgroundState.executionRun.finish_reason ?
                                    <>
                                        <div>&bull;</div>
                                        <div>{"Finish Reason: " + playgroundState.executionRun.finish_reason}</div>
                                    </>
                                    : null
                            }
                        </div>
                    </>
                )
            }

            <Tabs tabs={getTabs(playgroundState)} current={getCurrentTab(status)}>
                <TabsBar />
                <PanelErrorBoundary>
                    <TabsPanel />
                </PanelErrorBoundary>
            </Tabs>
        </EditorLayout>
    );
}
