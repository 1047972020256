import { useState } from 'react';

import SelectObjectTypeModal from '@/modules/store/features/store/objects/SelectObjectTypeModal';
import { useUserSession } from '@/session/UserSession';
import { Button, useToast } from '@reactik/components';
import { JSONDisplay } from '@reactik/json';
import { ExecutionRun } from '@vertesia/common';

interface TextOutputDisplayProps {
    run: ExecutionRun;
}
const TextOutputDisplay = ({ run }: TextOutputDisplayProps) => {
    const { client } = useUserSession();
    const toast = useToast();
    const [open, setOpen] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [IS_JSON] = useState(isJSON(run));

    const handleOnOpen = () => {
        setOpen(true);
    };

    const handleOnClose = async (typeId?: string | null | undefined) => {
        setOpen(false);

        if (typeId === undefined) {
            return;
        }

        setUploading(true);

        const isoDate = new Date().toISOString();
        const file = textToFile(run.result, IS_JSON, `generated-${isoDate}`);

        await client.objects.create({
            type: typeId || undefined,
            content: file
        }).then((res) => {
            toast({
                title: 'Document Upload Success',
                status: 'success',
                description: `Dcoument ${res.id} successfully uploaded`,
                duration: 3000
            });
        }).catch((error: any) => {
            toast({
                title: 'Document Upload Error',
                status: 'error',
                description: `Failed to upload document: ${error.message}`,
                duration: 3000
            });
        });
        setUploading(false);
    };

    return (
        <>
            {
                run.result &&
                <div className="flex justify-end mt-4">
                    <Button onClick={handleOnOpen} isDisabled={isUploading}>Save</Button>
                    <SelectObjectTypeModal onClose={handleOnClose} isOpen={open} actionLabel="Upload" title="Select Content Type">
                        <div className="text-sm">
                            Select the associated Content Type, or let the system choose or generate the type based on the content.
                        </div>
                    </SelectObjectTypeModal>
                </div>
            }
            <div className="w-full mt-4">
                {
                    IS_JSON &&
                    <JSONDisplay value={run.result} />
                }
                {
                    !IS_JSON &&
                    <pre className="whitespace-pre-wrap">{run?.result as string}</pre>
                }
            </div>
        </>
    );
};

function textToFile(content: string, isJson: boolean, filename: string) {
    const value = isJson ? JSON.stringify(content, null, 2) : content;
    const type = isJson ? 'application/json' : 'text/plain';
    const extension = isJson ? 'json' : 'txt';

    const blob = new Blob([value], { type: type });

    return new File([blob], `${filename}.${extension}`, { type: type });
}

function isJSON(run: ExecutionRun): boolean {
    return (run.result && typeof run.result === "object");
}

export default TextOutputDisplay;