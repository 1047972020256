export function RunStatus({ status }: { status: string; }) {
    const colors: Record<string, string> = {
        completed: "bg-green-500",
        failed: "bg-red-500",
        running: "bg-yellow-500",
        pending: "bg-yellow-500",
        processing: "bg-blue-500",
        created: "bg-pink-500",
        unknown: "bg-gray-500",
    };

    //put a colored dot before the status
    return (
        <div className="flex items-center">
            <span
                className={`w-2 h-2 rounded-full mx-2 ${colors[status] ?? ""}`}
            />{" "}
            {status}
        </div>
    );
}