import { useEffect, useState } from 'react';

import ExecutionRequestView from '@/modules/studio/features/interaction/playground/ExecutionRequestView';
import ExecutionResultView from '@/modules/studio/features/interaction/playground/ExecutionResultView';
import PlaygroundState from '@/modules/studio/features/interaction/playground/PlaygroundState';
import { useUserSession } from '@/session/UserSession';
import { Interaction } from '@vertesia/common';
import { FullHeightLayout } from '@reactik/layout';
import { useLocation } from '@reactik/router';

interface PlaygroundViewProps {
    interaction: Interaction;
}
export default function PlaygroundView({ interaction }: PlaygroundViewProps) {
    const { search } = useLocation();
    const { client } = useUserSession();
    const [pgState, setPgState] = useState<PlaygroundState>();

    useEffect(() => {
        const replayId = new URLSearchParams(search).get("replay_id");
        if (replayId) {
            client.runs.retrieve(replayId).then((run) => {
                if (run.interaction.id !== interaction.id) {
                    return;
                }

                setPgState(new PlaygroundState(client, {
                    ...interaction,
                    result_schema: run.result_schema,
                    test_data: run.parameters,
                    environment: run.environment.id,
                    model: run.modelId,
                    max_tokens: run.config?.max_tokens,
                    temperature: run.config?.temperature,
                    top_k: run.config?.top_k,
                    top_p: run.config?.top_p,
                    top_logprobs: run.config?.top_logprobs,
                    presence_penalty: run.config?.presence_penalty,
                    frequency_penalty: run.config?.frequency_penalty,
                    stop_sequence: run.config?.stop_sequence,
                    restriction: run.config?.run_data,
                }));
            });
        } else {
            setPgState(new PlaygroundState(client, interaction));
        }
    }, [interaction, search])

    return pgState ? (
        <PlaygroundState.Provider value={pgState}>
            <FullHeightLayout>
                <div className="w-full h-full flex items-stretch gap-x-4">
                    <div className="w-1/2 relative">
                        <ExecutionRequestView />
                    </div>
                    <div className="w-1/2 min-w-max relative">
                        <ExecutionResultView />
                    </div>
                </div>
            </FullHeightLayout>
        </PlaygroundState.Provider>
    ) : null;
}