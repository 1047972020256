import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { AIModel } from '@llumiverse/core';
import { Button, useToast } from '@reactik/components';

interface ModelIdBoxProps {
    model: AIModel;
}
export default function ModelIdBox({ model }: ModelIdBoxProps) {
    const toast = useToast();

    const onCopy = () => {
        navigator.clipboard.writeText(model.id);
        toast({
            status: 'info',
            title: 'ID copied to clipboard',
            duration: 1000
        })
    }

    return (
        <div className="text-sm flex gap-2">
            <div className="flex-shrink-0">ID:</div>
            <div className="group flex flex-1 min-w-0 text-gray-600 dark:text-slate-200">
                <div className="truncate flex-1">{model.id}</div>
                <div className="flex-shrink-1 relative">
                    <Button variant="soft" className="absolute hidden group-hover:block -top-6" onClick={onCopy}>
                        <ClipboardDocumentIcon className="w-8 h-8" />
                    </Button>
                </div>
            </div>
        </div>
    );
}
