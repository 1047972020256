import { SelectBox } from '@reactik/components';
import { RunDataStorageLevel, RunDataStorageOptions } from '@vertesia/common';

interface SelectRunDataLevelProps {
    value?: string;
    onChange: (value: string) => void
}
export default function SelectRunDataLevel({ value, onChange }: SelectRunDataLevelProps) {
    const runDataOption = (option: string): React.ReactNode => {
        return (
            <div className="flex flex-col">
                <div className="w-full font-semibold">{option}</div>
                <div className="w-full text-xs text-wrap">
                    {RunDataStorageOptions[option as keyof typeof RunDataStorageOptions]}
                </div>
            </div>
        )
    };

    return (
        <SelectBox
            className='flex-1 w-full'
            value={value ?? RunDataStorageLevel.STANDARD}
            options={Object.keys(RunDataStorageLevel)}
            optionLabel={runDataOption}
            placeholder="Select Run Data Level"
            filterBy="name"
            onChange={onChange}
        />
    );
}
