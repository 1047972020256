import SigninScreen from "@/features/login/SigninScreen";
import SelectProjectNav from "@/features/project/SelectProjectNav";
import PlaygroundView from "@/modules/studio/features/interaction/playground/PlaygroundView";
import { useUserSession } from "@/session/UserSession";
import { AuthTokenPayload, Interaction, PopulatedInteraction } from "@vertesia/common";
import { Button, ErrorBox, useToast } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import { FullHeightLayout } from "@reactik/layout";

interface PublicInteractionViewProps {
    interactionId: string;
}
export default function PublicInteractionView({ interactionId }: PublicInteractionViewProps) {
    const { client, project } = useUserSession();
    //const { data: projects } = useFetch(() => client.projects.list(), [project?.account]);

    const { data: interaction, error } = useFetch(() => {
        return client.get(`/public/interactions/${interactionId}`).then(interaction => {
            // remove default env and model from public view
            (interaction as any).environment = undefined;
            (interaction.model as any) = undefined;
            return interaction as PopulatedInteraction;
        });
    }, [interactionId]);

    if (error) {
        return <ErrorBox title="Failed to fetch interaction">{error.message}</ErrorBox>;
    }

    return interaction && <PlaygroundViewWrapper key={interaction.id} interaction={interaction} />;
}

interface PlaygroundViewWrapperProps {
    interaction: Interaction;
}
function PlaygroundViewWrapper({ interaction }: PlaygroundViewWrapperProps) {
    const toast = useToast();
    const { client, user, isLoading } = useUserSession();
    const { project } = useUserSession();

    const onCopyInteraction = () => {
        const r = window.confirm(`Are you sure you want to copy this interaction in the project: ${project?.name ?? "unknown"}?`);
        if (r) {
            client.interactions.fork(interaction.id, { keepTags: true, forkPrompts: true }).then(() => {
                toast({
                    status: 'success',
                    title: `Interaction copied in ${project?.name}`,
                    duration: 3000
                })
            }).catch(err => {
                toast({
                    status: 'error',
                    title: 'Failed to copy interaction',
                    description: err.message,
                    duration: 6000
                })
            })
        }
    }
    return (
        <FullHeightLayout>
            <div className='px-4 py-8 mb-4 bg-gray-50 dark:bg-inherit border-b border-solid border-gray-200 dark:border-slate-700'>
                <div className='flex items-center'>
                    <h1 className='text-2xl font-bold'>{interaction.name}</h1>
                    {user &&
                        <div className='ml-auto flex gap-x-2 items-center'>
                            <SelectProjectNav />
                            <Button variant='primary' size='md' onClick={onCopyInteraction}>Copy Interaction</Button>
                        </div>
                    }
                </div>
                <p className='text-gray-500'>{interaction.description}</p>
            </div>
            <FullHeightLayout.Body>
                {getPlaygroundView(user, isLoading, interaction)}
            </FullHeightLayout.Body>
        </FullHeightLayout>
    )
}

function getPlaygroundView(user: AuthTokenPayload | undefined, isUserLoading: boolean, interaction: Interaction) {
    if (isUserLoading) {
        return null;
    }
    if (user) {
        return <PlaygroundView interaction={interaction} />;
    } else {
        return <SigninScreen isNested />;
    }
}
