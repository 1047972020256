import React from 'react';

import { ColumnLayout, ContentObjectItem } from '@vertesia/common';

import renderers from './renderers';

const defaultRenderer = renderers.string();


function resolveField(object: ContentObjectItem, path: string[]) {
    let p = object as any;
    if (!p) return undefined;
    if (!path.length) return p;
    const last = path.length - 1;
    for (let i = 0; i < last; i++) {
        p = p[path[i]];
        if (!p) {
            return undefined;
        }
    }
    return p[path[last]];
}

function splitPath(path: string) {
    if (!path || path === '.') {
        return [];
    }
    return path.split('.');
}

export class TableColumn {
    renderer: (value: any, index: number) => React.ReactNode = defaultRenderer;
    path: string[];
    fallbackPath?: string[];
    constructor(public layout: ColumnLayout) {
        this.path = splitPath(layout.field);
        this.fallbackPath = layout.fallback ? splitPath(layout.fallback) : undefined;
        const type = layout.type || 'string';
        const i = type.indexOf('?');
        if (i > 0) {
            const name = type.substring(0, i);
            const params = new URLSearchParams(type.substring(i + 1));
            this.renderer = renderers[name](params);
        } else {
            this.renderer = renderers[type]();
        }
    }

    get name() {
        return this.layout.name;
    }

    resolveValue(object: ContentObjectItem) {
        let value = resolveField(object, this.path);
        if (value === undefined && this.fallbackPath) {
            value = resolveField(object, this.fallbackPath);
        }
        if (value === undefined) {
            value = this.layout.default;
        }
        return value;
    }

    render(object: ContentObjectItem, index: number) {
        return this.renderer(this.resolveValue(object), index);
    }

}
