import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useMemo, useState } from 'react';

import { ApiKey, ApiKeyWithValue } from '@vertesia/common';
import { Button, EmptyCollection, Switch, TBody, useToast } from '@reactik/components';
import { useCompositeState, useDefineSlot, useFetch } from '@reactik/hooks';

import { useUserSession } from '../../session/UserSession';
import APIKeyCreateModal, { ShowKeyValueModal } from './APIKeyCreateModal';
import APIKeyDeleteModal from './APIKeyDeleteModal';
import HeaderActionsState from './HeaderActionsState';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat)

export default function APIKeysView() {
    const { client } = useUserSession();
    const [keys, setKeys] = useState<ApiKey[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showCreate, setShowCreate] = useState(false);
    const [activeKey, setActiveKey] = useState<ApiKeyWithValue | undefined>(undefined);
    const [_isProcessing, setIsProcessing] = useState(false);
    const [refetch, setRefetch] = useState(true);

    const { data: projects } = useFetch(() => client.projects.list(), [])

    const headerState = useCompositeState(HeaderActionsState);
    useDefineSlot(headerState.action, <Button key='create_api_key' onClick={() => setShowCreate(true)}>Create New API Key</Button>);

    useEffect(() => {
        if (!refetch) {
            return;
        }

        client.apikeys.list().then((keys) => {
            setKeys(keys);
        }).catch((err) => {
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
            setRefetch(false);
        });
    }, [refetch]);

    const showKey = (id: string) => {
        setIsProcessing(true);
        client.apikeys.retrieve(id, true).then((key) => {
            setActiveKey(key as ApiKeyWithValue);
        }).catch((err) => {
            setError(err.message);
        }).finally(() => {
            setIsProcessing(false);
            setRefetch(false);
        });
    };

    const formatProjectName = (key: ApiKey) => {
        if (!key.project) {
            return <span>all</span>;
        }

        const project = projects?.find(p => p.id === key.project);

        if (!project) {
            return <span>unknown</span>;
        }

        return <>{project.name}<br /><span className="text-xs">{project.id}</span></>;
    }

    const switchActivation = (key: ApiKey, enable: boolean) => {
        return client.apikeys.update(key.id, { ...key, enabled: enable }).then((newKey) => {
            console.log(newKey);
            //replace key in array to update the table
            const index = keys.findIndex((k) => k.id === newKey.id);
            if (index > -1) {
                const newKeys = [...keys];
                newKeys[index] = newKey;
                setKeys(newKeys);
            }
        });
    };

    return (
        <div>
            {error && <div className="text-red-500">{error}</div>}
            {!isLoading && !error && keys.length === 0 &&
                <div className="text-gray-500">
                    <EmptyCollection title="No API Keys" buttonLabel='New API Key' onClick={() => setShowCreate(true)}>
                        Get started by creating a new API Key.
                    </EmptyCollection>
                </div>
            }
            {(!isLoading && keys.length > 0 || isLoading) && <>
                <div className="overflow-x-auto">
                    <table className="table-simple w-full">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Project</th>
                                <th>Created</th>
                                {/*<th>Last Used</th>*/}
                                <th>Expires In</th>
                                <th>Enabled</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <TBody isLoading={isLoading} columns={7}>
                            {keys.map((key) => (
                                <tr key={key.id}>
                                    <td>
                                        <Button variant="soft" onClick={() => showKey(key.id)}>{key.maskedValue}</Button>
                                    </td>
                                    <td>{key.name}</td>
                                    <td>{key.role}</td>
                                    <td>{formatProjectName(key)}</td>
                                    <td>{dayjs(key.created_at).format("ll")}</td>
                                    {/*<td>-</td>*/}
                                    <td>{key.expires_at ? dayjs().to(dayjs(key.expires_at)) : '-'}</td>
                                    <td align="center">
                                        <StatusSwitch apiKey={key} onChange={switchActivation} />
                                    </td>
                                    <td align="center">
                                        <APIKeyDeleteModal apiKey={key} setRefetch={setRefetch} setError={setError} />
                                    </td>
                                </tr>
                            ))}
                        </TBody>
                    </table>
                </div>
            </>
            }
            <APIKeyCreateModal isOpen={showCreate} onClose={() => setShowCreate(false)} setActiveKey={setActiveKey} setRefetch={() => setRefetch(true)} />
            <ShowKeyValueModal setActiveKey={setActiveKey} activeKey={activeKey} />
        </div>
    )
}

function StatusSwitch({ apiKey, onChange }: { apiKey: ApiKey, onChange: (key: ApiKey, value: boolean) => Promise<void> }) {
    // we use the enabled flag to imeddiately toggle the swicth otherwise a latence apperrs until the switch is updated
    const toast = useToast();
    const [enabled, setEnabled] = useState(apiKey.enabled);
    const _onChange = (value: boolean) => {
        setEnabled(value);
        onChange(apiKey, value).catch(err => {
            setEnabled(apiKey.enabled);
            toast({
                title: "Failed to update ${apiKey.name} status",
                description: err.message,
                status: "error",
                duration: 9000
            });
        });
    }
    return useMemo(() => <Switch onChange={_onChange} value={enabled} />, [apiKey.enabled, enabled]);
}
