import { useState } from 'react';

import { ProjectSelector } from '@/features/project/SelectProjectNav';
import { useUserSession } from '@/session/UserSession';
import { InviteUserRequestPayload, ProjectRef, ProjectRoles } from '@vertesia/common';
import { Button, Input, SelectBox, useToast } from '@reactik/components';
import { useFetch } from '@reactik/hooks';

export function InviteUserToOrgWidget() {

    const { account, client } = useUserSession();
    const [emailToInvite, setEmailToInvite] = useState<string | undefined>(undefined);
    const [inviteeRole, setInviteeRole] = useState<ProjectRoles | undefined>(ProjectRoles.developer);
    const [selectedProject, setSelectedProject] = useState<ProjectRef | undefined>(undefined);
    const toast = useToast();

    const { data: projects } = useFetch(() => client.projects.list(account ? [account.id] : undefined), [client, account]);


    const inviteUser = () => {
        if (!emailToInvite) return;
        if (!inviteeRole) return;

        const payload: InviteUserRequestPayload = {
            email: emailToInvite,
            role: inviteeRole,
            projects: selectedProject ? [selectedProject.id] : undefined
        }

        client.account.inviteUser(payload).then((res) => {
            toast({
                status: 'success',
                title: res.action === 'added' ? 'User added to your team' : 'User invited by email',
                description: res.action === 'added' ? `User ${emailToInvite} is now part of your team` : `The invite has been sent to ${emailToInvite}`
            })
            setEmailToInvite(undefined);
        })
    }

    return (
        <div>
            <div className="w-full flex flex-row gap-4">
                <Input className="w-full" onChange={setEmailToInvite} value={emailToInvite} placeholder="email address" />
                {projects && <ProjectSelector selected={selectedProject} projects={projects} onChange={setSelectedProject} />}
                <SelectBox options={Object.values(ProjectRoles)} value={inviteeRole} onChange={setInviteeRole} />
                <Button onClick={inviteUser} size="md">Invite User</Button>
            </div>
            <div className="pt-4 italic text-sm">Note: do not select any project to set the role at organization level.</div>
        </div>
    )

}
