import { Account, Permission } from "@vertesia/common";
import { Button, Input, useToast } from "@reactik/components";
import { useState } from "react";
import { useUserSession } from "../../session/UserSession";
import SettingsPanel from "./SettingsPanel";
import SecureButton from "@/session/permissions/SecureButton";



export default function AccountSettings({ account }: { account: Account }) {

    const { client } = useUserSession();
    const [accountName, setAccountName] = useState(account?.name);
    const [emailDomains, setEmailDomains] = useState<string | undefined>(() => account.email_domains.join(', '));
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();


    const save = () => {
        setIsLoading(true);
        const domains: string[] | undefined = emailDomains?.split(',').map((d: string) => d.trim());
        client.account.update({ name: accountName, email_domains: domains }).finally(() => {
            toast({
                title: "Account updated",
                description: "Your account has been updated.",
                status: "success",
                duration: 2000,
            })
            setIsLoading(false);
        });
    }

    const saveButton = <SecureButton permission={Permission.account_manage} size="md" variant="primary" onClick={save} isLoading={isLoading}>Save</SecureButton>

    return (
        <SettingsPanel title="Account Information" footer={saveButton}>

            <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">
                    <div className="w-48">Account Name</div>
                    <Input className="w-96" value={accountName} onChange={setAccountName} />
                </div>
                <div className="flex flex-row gap-4">
                    <div className="w-48">Email Domains</div>
                    <Input className="w-96" value={emailDomains} onChange={setEmailDomains} />

                </div>

            </div>


        </SettingsPanel >
    )

}